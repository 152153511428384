import styled, { keyframes } from 'styled-components'

import { colors, medias } from 'styles'

export const Container = styled.div`
  padding: 16px;
  background-color: #333;
  padding-top: 56px;
  padding-bottom: 82px;
`

export const Header = styled.div`
  width: 100%;
  padding: 8px 16px;
  position: fixed;
  top: 0;
  min-height: 48px;
  left: 0;
  background-color: #333;
`

export const Footer = styled.div`
  width: 100%;
  padding: 8px 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  position: fixed;
  bottom: 0;
  height: 66px;
  left: 0;
  background-color: #333;
  display: flex;
  justify-content: space-between;
`

export const Title = styled.h2`
  font-size: 20px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: ${medias.xs}) {
    font-size: 16px;
    line-height: 32px;
  }
`

export const QuestionTitle = styled.p`
  font-size: 24px;
  line-height: 48px;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 700;

  & > span {
    display: inline-block;
    margin-right: 8px;
    color: ${colors.textColorSecondary};
  }

  @media (max-width: ${medias.xs}) {
    font-size: 22px;
    line-height: 32px;
  }
`
export const Option = styled.div`
  margin: 8px;
  width: ${({ fullwidth }) => (fullwidth ? '100%' : 'unset')};
`

export const OptionsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 -8px;
`

export const Image = styled.img`
  max-width: 100%;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
`

const createKeyframes = () => {
  const height = 328
  let i = 0
  let keyframes = ''

  while (i <= 10) {
    keyframes += `
      ${i === 0 ? '0%' : `${i}0%`} {
        transform: translate3d(0, -${i === 0 ? height : height * (i + 1)}px, 0);
      }
    `
    i++
  }
  return keyframes
}

const sliding = keyframes`
  ${createKeyframes()}
`

export const SlidingVertical = styled.div`
  margin: 8px;
  width: 200px;
  height: 320px;
  overflow: hidden;
  text-align: center;

  & span {
    display: flex;
    height: 320px;
    animation: ${sliding} 10s ease-in-out;
    animation-delay: ${({ delay }) => delay}ms;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: center;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  & img {
    height: 270px;
    max-width: 100%;
    margin-bottom: 5px;
    object-fit: contain;

    @media (max-width: 480px) {
      height: 60%;
    }
  }
`

export const FinalTitle = styled(Title)`
  width: 70%;
  font-size: 32px;
  margin-bottom: 24px;
  margin-top: 32px;

  @media (max-width: ${medias.xs}) {
    font-size: 16px;
    line-height: 32px;
  }
`

// Progress Bar
export const ProgressBar = styled.div`
  width: 110%;
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  position: relative;
  bottom: 0;
  left: 0;
  margin: 8px -16px 0 -16px;
`
export const ProgressBarInside = styled.div`
  width: 100%;
  height: 4px;
  background: #fbc62c;
`
