import styled from 'styled-components'

import { colors, medias } from 'styles'

export const Footer = styled.footer`
  width: 100%;
  background-color: ${colors.lightBackground};
  display: flex;
  padding: 1.5rem 0;
  margin-top: 2rem;

  @media (max-width: ${medias.xs}) {
    text-align: center;

    .ant-space,
    .ant-space-item {
      width: 100%;
    }

    .ant-col {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
`

export const FooterLink = styled.a`
  color: ${colors.textColorSecondary};

  &:hover {
    color: ${colors.primaryColor};
  }
`
