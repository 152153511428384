import { createGlobalStyle } from 'styled-components'

import { typography, colors, sizes } from './variables'

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  small {
    font-size: 85%;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  .app-html {
    overflow-x: hidden;
  }

  body {
    background-color: ${({ bgColor }) => (bgColor ? bgColor : colors.defaultBackground)};
    font-family: ${typography.fontFamily};
    font-size: ${typography.fontSize};
    color: ${colors.defaultTextColor};
  }

  a {
    text-decoration: none;
  }

  .ant-btn {
    font-size: 14px;
    text-transform: uppercase;
  }

  .ant-btn-lg {
    font-size: 16px;
  }

  .ant-btn-sm {
    line-height: 1;
  }

  .ant-card {
    border: 0;
  }

  .ant-table-bordered {
    & td,
    & th {
      border-color: ${colors.tableBorderColor} !important;
    }
  }

  .ant-table.ant-table-bordered .ant-table-container {
    border-color: ${colors.tableBorderColor} !important;
  }

  .ant-space {
    display: flex;
  }

  .ant-message-notice-content {
    background: ${colors.messageBg}
  }

  .ant-tag {
    background: rgba(0, 0, 0, 0);
  }

  .ant-modal-footer,
  .ant-modal-header {
    border-color: ${colors.tableBorderColor};
  }

  .ant-dropdown-menu-item:hover {
    background-color: ${colors.lightBackground};
  }

  .side-menu {
    border: 0;
  }

  .side-menu .ant-menu-item {
    height: ${sizes.heightBase};
    line-height: ${sizes.heightBase};

    &:hover {
      background-color: rgba(255, 255, 255, 0.25);
      color: ${colors.defaultTextColor}
    }

    &.ant-menu-item-active a {
      color: ${colors.defaultTextColor}
    }
  }

  .menu-avatar .ant-menu-submenu-title {
    height: 48px;
    display: flex;
    align-items: center;
  }

  .menu-avatar .ant-menu-submenu-title > div {
    display: flex;
    height: 32px;
  }


  *::-webkit-scrollbar {
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  .ant-input-search-icon:before {
    border: 0;
  }

  .ant-input-password-icon:hover {
    color: ${colors.primaryColor} !important;
  }

  .react-grid-item > .react-resizable-handle::after {
    border-right: 2px solid rgb(255, 255, 255, 0.4) !important;
    border-bottom: 2px solid rgb(255, 255, 255, 0.4) !important;
  }
`

export default GlobalStyles
