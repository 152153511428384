const colors = {
  primaryColor: process.env.REACT_APP_PRIMARY_COLOR,
  btnPrimaryColor: process.env.REACT_APP_BUTTON_PRIMARY_COLOR,
  defaultBackground: process.env.REACT_APP_BASE_BACKGROUND_COLOR,
  componentBackgroun: '#363636',
  headerBackground: '#191919',
  lightBackground: '#222',
  lighterBackground: '#5A5A5A',
  darkBackground: '#0e0e0e',
  defaultTextColor: '#fff',
  textColorSecondary: 'rgba(255, 255, 255, 0.6)',
  tableBorderColor: '#535454',
  disabledBg: '#808080',
  disabledColor: 'rgba(0, 0, 0, 0.6)',
  messageBg: '#000',
  lightBorderColor: 'rgba(255, 255, 255, 0.45)',
  green: '#60E448',
  lightGreen: '#8bc34a',
  orange: '#ff5722',
  blue: '#03a9f4',
  red: '#f11f1f',
}

const typography = {
  fontFamily: '"Montserrat", sans-serif',
  fontText:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  textSize: '16px',
  textSizeSmall: '14px',
}

const sizes = {
  defaultBorderRadius: '8px',
  smallBorderRadius: '4px',
  heightBase: '48px',
  heightLg: '64px',
  heightSm: '40px',
}

const medias = {
  xs: '480px',
  sm: '768px',
  md: '1024px',
  lg: '1400px',
  xl: '1920px',
}

module.exports = {
  colors,
  typography,
  sizes,
  medias,
}
