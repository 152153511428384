import React, { useEffect, useContext } from 'react'
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom'
import { useFetch } from 'use-http'
import { createGlobalStyle } from 'styled-components'

import ControlCenterPage from './control-center'
import MarketingAndSellsPage from './marketing-and-sells'
import AdminPanelLoginPage from './login'

import { useAdminPanel, ACTIONS } from 'context/admin-panel-context'
import { createAdminGetUrl } from 'utils'
import { colors } from 'styles'

const PrivateRoute = ({ children, pageId, ...rest }) => {
  const history = useHistory()
  const [{ user: userFromState, permissions }, dispatch] = useAdminPanel()

  const { get: getUserSession } = useFetch(createAdminGetUrl('ValidaSessaoDash'), null)

  const verifyUserSession = async () => {
    const [isValid] = await getUserSession()

    dispatch({ type: ACTIONS.SET_USER_PERMISSIONS, payload: isValid.permissoes })

    if (isValid.sessao !== 'VALIDA') {
      dispatch({ type: ACTIONS.SET_USER, payload: null })
      return history.push('/painel/login')
    }
  }

  useEffect(() => {
    const userFromBrowserSession = window.localStorage.getItem('adp_admin:user_session')

    if (!userFromState) {
      if (!userFromBrowserSession) return history.push('/painel/login')
      const parsedUser = JSON.parse(userFromBrowserSession)

      dispatch({ type: ACTIONS.SET_USER, payload: parsedUser })
      dispatch({ type: ACTIONS.SET_USER_PERMISSIONS, payload: parsedUser.permissoes })

      verifyUserSession()
    }
  }, [userFromState])

  useEffect(() => {
    if (permissions && permissions.length > 0 && !permissions.includes(pageId)) {
      history.push('/painel/404')
    }
  }, [permissions])

  return <Route {...rest}>{children}</Route>
}

const GlobalStyle = createGlobalStyle`
  td.ant-table-cell.small-font {
    font-size: 13px;
  }
  
  .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .ant-table-filter-dropdown-btns .ant-btn.ant-btn-link.ant-btn-sm {
    color: #fff !important;
  }

  .ant-table-filter-dropdown-btns  {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .ant-checkbox-inner {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .generic-card {
    .ant-select-multiple .ant-select-selection-item {
      background-color: #141414;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .ant-table-footer {
      background: rgba(255, 255, 255, 0.1);
      display: flex;
      justify-content: flex-end;
      border-radius: 0 !important;
    }

    .ant-progress-inner {
      background-color: rgba(255, 255, 255, 0.2);
    }

    td.ant-table-column-sort {
      background: rgba(255, 255, 255, 0.1);
    }

    .ant-table-container {
      overflow-x: auto;
    }

    & .ant-table {
      background-color: rgba(255, 255, 255, 0);
      margin: 0 -16px;
    }

    & .ant-table-tbody > tr > td {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    & .ant-typography.ant-typography-secondary {
      font-size: 14px;
      height: 18px;
      line-height: 16px;
      display: block;
    }

    & .ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
      padding: 11px 16px;
    }

    .ant-btn.ant-dropdown-trigger.ant-btn-text.ant-btn-sm {
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    th.ant-table-cell  {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
      border-color: rgba(255, 255, 255, 0.25);
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: transparent;
    }

    .ant-table-small .ant-table-thead > tr > th {
      background-color: #2b2b2b;
    }
  }

  .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  select {
    color: #333 !important;
  }

  select option{
    color: #333 !important;
  }

  .rdrDefinedRangesWrapper,
  .rdrStaticRange,
  .rdrMonths.rdrMonthsHorizontal,
  .rdrMonthAndYearWrapper,
  .rdrDateDisplayItem,
  .rdrDateDisplayWrapper {
    background: #323232 !important;
  }

  .rdrNextPrevButton {
    background-color: rgba(255, 255, 255, 0.1)
  }

  .rdrStaticRange:hover .rdrStaticRangeLabel,
  .rdrStaticRange:focus .rdrStaticRangeLabel {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .rdrPprevButton i {
    border-color: transparent rgb(255 255 255) transparent transparent;
  }
  .rdrNextButton i { 
    border-color: transparent transparent transparent rgb(255 255 255);
  }

  .rdrDateDisplayItemActive input,
  .rdrMonthAndYearPickers select,
  .rdrDayNumber span {
    color: #fff !important;
  }

  .rdrDayPassive .rdrDayNumber span {
    color: rgba(255, 255, 255, 0.3) !important;
  }

  .rdrMonthAndYearPickers select {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .rdrStaticRange {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .rdrDefinedRangesWrapper {
    border-right: solid 1px rgba(255, 255, 255, 0.1);
  }

  .rdrInputRanges {
    display: none;
  }

  .rdrDateDisplay,
  .rdrDayHovered {
    color: ${colors.primaryColor} !important;
  }

  .rdrDefinedRangesWrapper{
    @media (max-width: 768px) {
      display: none;
    }
  }

`

const AdminRouter = () => {
  return (
    <Router>
      <GlobalStyle />

      <Switch>
        <Route path="/painel/login">
          <AdminPanelLoginPage />
        </Route>

        <PrivateRoute path="/painel" pageId="dash1" exact>
          <ControlCenterPage />
        </PrivateRoute>

        <PrivateRoute path="/painel/marketing-e-vendas" pageId="dash2">
          <MarketingAndSellsPage />
        </PrivateRoute>

        <Route path="/painel/404">
          <h1>Página não encontrada ou você não tem permissão</h1>
        </Route>
      </Switch>
    </Router>
  )
}

export default AdminRouter
