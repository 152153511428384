import styled from 'styled-components'

export const EmptyMessageList = styled.div`
  height: ${({ height }) => `calc(100% - 64px - 64px)`};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #262626;
`
