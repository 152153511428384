import { Button as AntButton } from 'antd'
import styled from 'styled-components'
import { colors } from '../../../styles'

export const Button = styled(AntButton)`
  position: absolute;
  right: 4px;
  top: 4px;
`

export const Form = styled.div`
  display: flex;
  gap: 16px;
`

export const HelpText = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);

  & > a {
    color: ${colors.primaryColor};
  }
`
