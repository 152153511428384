import styled from 'styled-components'
import { Space, Tabs } from 'antd'

export const BlockedInput = styled.div`
  display: flex;
  background-color: #363636;
  height: 64px;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
`

export const LoadingContainer = styled(Space)`
  padding-top: 96px;
  background-color: #222222;
  height: 100%;
`

export const StyledChatTabs = styled(Tabs)`
  background-color: #222222;
  height: 100%;

  & .ant-tabs-nav-wrap {
    padding-left: 16px;
  }

  & .ant-tabs-content-holder {
    overflow-y: auto;
  }
`
